import React from 'react';
import { ThemeProvider } from '../contexts/theme';
import { t } from '../../js/common/translations';
import {
  SectionHero,
  Hero,
  HeroTitle,
  ApartmentsSection,
  ApartmentsSectionTextWrapper,
  Section,
  SectionTitle,
  SectionSubtitle,
  ExperiencePictureLeft,
  ExperiencePictureRight,
  ImagesSection,
  HeroTextWrapper,
  WhatsappButton,
  ThirdSection,
  ThirdSectionContainer,
  ThirdSectionPolaroid,
  ThirdSectionPolaroidPicture,
  SectionMiniTitle,
  WhatsappButtonDark
} from './Landing.styles';

import {
  BuildingSection,
  CTABottomBar
} from '../UI/modules';
import {
  Box,
  Container,
  HtmlTranslation,
  SvgIcon,
  Typography
} from '../UI/elements';
import ExperienceImgLeft from '../../images/casatb/experience_left.png';
import ExperienceImgRight from '../../images/casatb/experience_right.png';
import PolaroidImgRight from '../../images/casatb/polaroid_right.png';
import PolaroidImgLeft from '../../images/casatb/polaroid_left.png';
import HeroCasatb from '../../images/casatb/Hero.jpg';

const CasatbLanding = ({
  buildings
}) => {
  return (
    <ThemeProvider>
      <SectionHero sx={{ height: { xs: '100vh', md: '398px' }, mt: { xs: '-55px', md: '0' } }}>
        <Hero component="picture">
          <Hero component="img" src={HeroCasatb} />
        </Hero>
        <HeroTextWrapper ctaShown={!!buildings.length}>
          <HeroTitle>
            <HtmlTranslation text={t('landing.title')} />
          </HeroTitle>
          <WhatsappButton>
            <SvgIcon name="whatsapp_light" sx={{ mr: '14px' }} />
            {t('landing.whatsapp_hero_button')}
          </WhatsappButton>
        </HeroTextWrapper>
      </SectionHero>
      <Container disableGutters sx={{ paddingY: { xs: 2, md: 3.5 }, paddingX: { xs: 1.5, sm: 2 } }}>
        <Section>
          <SectionTitle>
            {t('landing.best_experience_title')}
          </SectionTitle>
          <SectionSubtitle>
            {t('landing.casatb_unique_experience')}
          </SectionSubtitle>
          <SectionSubtitle>
            {t('landing.our_apartments')}
          </SectionSubtitle>
          <ImagesSection>
            <ExperiencePictureLeft component="picture">
              <ExperiencePictureLeft component="img" src={ExperienceImgLeft} />
            </ExperiencePictureLeft>
            <ExperiencePictureRight component="picture">
              <ExperiencePictureRight component="img" src={ExperienceImgRight} />
            </ExperiencePictureRight>
          </ImagesSection>
        </Section>
      </Container>
      {!!buildings.length &&
        <ApartmentsSection py={{ xs: 1, md: 3.5 }}>
          <Container sx={{ px: { xs: 1, md: 2 } }} maxWidth="xl">
            <ApartmentsSectionTextWrapper mb={-2} display="flex" flexDirection="column" alignItems="center">
              <SectionMiniTitle>{t('landing.sections.apartments.minititle')}</SectionMiniTitle>
              <SectionTitle sx={{ px: { xs: 0, md: '300px' } }}>
                <HtmlTranslation text={t('landing.sections.apartments.title_html')} />
              </SectionTitle>
              <SectionSubtitle>
                {t('landing.sections.apartments.subtitle')}
              </SectionSubtitle>
            </ApartmentsSectionTextWrapper>
            <BuildingSection
              buildings={buildings}
              casatb
            />
          </Container>
          <Box width='100%' px={2} display='flex' justifyContent='center'>
            <WhatsappButtonDark href={`/whatsapp_redirect?website_url=${location.href}&button_type=${t('campaign_tracker.button_type.sales')} ${t('landing.whatsapp_message')}`}>
              <SvgIcon name="whatsapp_light" sx={{ mr: '14px' }} />
              {t('landing.sections.apartments.whatsapp_button')}
            </WhatsappButtonDark>
          </Box>
        </ApartmentsSection>
      }
      <ThirdSection>
        <ThirdSectionContainer disableGutters>
          <Box display="flex" flexDirection='column' width={{ xs: '100%', md: '50%', zIndex: 2 }} >
            <Typography sx={{ color: 'secondary.main', fontSize: 40, fontWeight: 700 }} fontFamily="Cambon" mb={2} component="h2">
              {t('landing.location.title')}
            </Typography>
            <Typography fontSize={18} sx={{ color: 'secondary.main', mb: 1.5 }}>{t('landing.location.content_1')}</Typography>
            <Typography fontSize={18} sx={{ color: 'secondary.main', mb: 1.5 }}>{t('landing.location.content_2')}</Typography>
            <Typography fontSize={18} sx={{ color: 'secondary.main', mb: 1.5 }}>{t('landing.location.content_3')}</Typography>
          </Box>
          <Box
            position="relative"
            width={{ xs: '100%', md: '50%' }}
            display="flex"
            justifyContent={{ xs: 'center', sm: 'space-around' }}
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems='center'
            height={{ xs: 'auto', sm: '540px' }}
            mt={{ xs: 2.5, md: 0 }}>
            <ThirdSectionPolaroid py={1} left={{ xs: 0, md: 65 }} top={0} zIndex={1}>
              <ThirdSectionPolaroidPicture component="picture" >
                <ThirdSectionPolaroidPicture component="img" src={PolaroidImgLeft} sx={{ objectFit: 'cover' }} />
              </ThirdSectionPolaroidPicture>
              <Box mt={1}>
                <Typography color='primary.main'>
                  <HtmlTranslation text={t('landing.location.polaroid_title_1')} />
                </Typography>
              </Box>
            </ThirdSectionPolaroid>
            <ThirdSectionPolaroid p={1} right={0} bottom={0} zIndex={2}>
              <ThirdSectionPolaroidPicture component="picture" >
                <ThirdSectionPolaroidPicture component="img" src={PolaroidImgRight} sx={{ objectFit: 'cover' }} />
              </ThirdSectionPolaroidPicture>
              <Box mt={1}>
                <Typography color='primary.main'>
                  <HtmlTranslation text={t('landing.location.polaroid_title_2')} />
                </Typography>
              </Box>
            </ThirdSectionPolaroid>
          </Box>
        </ThirdSectionContainer>
      </ThirdSection>
      {!!buildings.length &&
        <CTABottomBar
          casatb={true}
          actionButtonProps={{
            children: (
              <>
                <SvgIcon name={'whatsapp_light'} size={20} mr={0.25} />
                {t('landing.whatsapp_us')}
              </>
            ),
            href: `/whatsapp_redirect?website_url=${location.href}&button_type=${t('campaign_tracker.button_type.sales')} ${t('landing.whatsapp_message')}`,
            target: '_blank',
            className: 'whatsapp-link'
          }}
          text={t('landing.whatsapp_text')}
        />
      }
    </ThemeProvider>
  );
};

export default CasatbLanding;
