import React, { useState } from 'react';
import { daysDiff } from '../../js/common/dates';
import formatCurrency from '../../js/common/formatCurrency';
import { t } from '../../js/common/translations';
import { ThemeProvider } from '../contexts/theme';
import {
  Box,
  Chip,
  Link,
  SvgIcon,
  Typography,
  HtmlTranslation
} from '../UI/elements';
import Info from '../../images/react_icons/Info';
import { AdyenPaymentComponent } from '../UI/modules';
import {
  ContentWrapper,
  SuccessTypography,
  PolicyLink,
  ChipLabel,
  ItemNumberWrapper,
  BankInfoText
} from './CheckoutPayment.styles';
import { Personal, Nationality, AdditionalInfo, Address, AdditionalGuests, Success } from '../UI/modules/reservation-form/steps';

const CheckoutPayment = ({
  adyenCallbackSuccess,
  tabasAdyenClientKey,
  adyenCardResponse,
  adyenSaveLogPath = null,
  adyenEnvironment,
  paymentMethod,
  adyenActionPath,
  neighbourhood,
  checkOutDate,
  googleAppUrl,
  paymentType,
  paymentLink,
  appleAppUrl,
  policyLinks,
  checkInDate,
  propertyImg,
  cityName,
  address,
  total,
  paid = false,
  step,
  reservationForm,
  reservationFormSubmitPath,
  previousStepUrl,
  nextStepUrl,
  guestInfo = null,
  guestNextStepUrl = null,
  guestPreviousStepUrl = null,
  guestNumber = null,
  currGuestsCount = null,
  maxGuests,
  maxCheckInTime,
  availableCheckInHours,
  formStatus,
  reservationStatus,
  bankAccount,
  isPetFriendly,
  hasParkingSlot,
  parkingSlotCount,
  adyenSessionInfo = null,
  reservationFormPath = null,
  reservationSource,
  showSentRegularContractEmail,
  simplifiedForm
}) => {
  const [paymentSucceeded, setPaymentSucceeded] = useState(paid);
  const numberOfNights = daysDiff(new Date(checkInDate), new Date(checkOutDate));

  const Copyright = ({ textColor, fontSize, ...props }) => (
    <Box display="flex" flexDirection="column" my={1} {...props}>
      <SuccessTypography
        textAlign="center"
        variant="lead"
        sx={{ color: textColor, fontSize: fontSize }}
      >
        {t('footer.copyright')}
      </SuccessTypography>
      <Box>
        {policyLinks.map(({ href, description }, index) => (
          <PolicyLink
            sx={{ color: textColor, fontSize: fontSize }}
            index={index}
            key={index}
            href={href}>
            {description}
          </PolicyLink>
        ))}
      </Box>
    </Box>
  );

  const WireTransferMessage = () => (
    <ContentWrapper
      bgcolor="secondary.main"
      textAlign="center"
      justifyContent="center"
      mt={5}
      px={{ xs: 1, md: 3 }}
    >
      <Box
        className={`rent-requested-${paymentMethod}`}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        px={2}
        mb={2}
      >
        <SvgIcon
          className={`rent-requested-${paymentMethod}-success-img`}
          name="success_large"
          mb={1.5}
        />
        <Typography
          fontWeight="bolder"
          color="primary"
          gutterBottom
          variant="h4"
        >
          {t('checkout_success_page.success.title')}
        </Typography>
        <Typography
          fontWeight={400}
          color="primary"
          gutterBottom
          variant="h4"
        >
          {t('checkout_success_page.success.submit_message')}
        </Typography>
        <Typography
          fontWeight={500}
          gutterBottom
          color="primary.70"
          mb={2}
          fontSize={14}
          variant="h4"
        >
          {t('checkout_success_page.success.wire_transfer.message')}
        </Typography>
        <Box
          display="flex"
          mb={1.5}
        >
          <Box
            bgcolor="warning.light"
            borderRadius={1}
            border={1}
            borderColor="warning.dark"
            padding={0.5}
            textAlign="left"
          >
            <Typography
              color="warning.dark"
              fontSize="0.875rem"
            >
              <Info sx={{ marginRight: '0.5rem', width: '1rem', height: '1rem' }} />
              {t('checkout_success_page.success.wire_transfer.warning')}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          mb={4}
        >
          <Box display="flex">
            <ItemNumberWrapper>
              <Typography>{'1'}</Typography>
            </ItemNumberWrapper>
            <Typography
              alignSelf="center"
              textAlign="left"
            >
              {t('checkout_success_page.success.wire_transfer.step_1')}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            my={0.5}
            ml="calc(30px + 0.5rem)"
          >
            {bankAccount ? (
              <>
                <Typography fontWeight={500}>
                  {t('checkout_success_page.success.wire_transfer.company')}
                </Typography>
                {bankAccount.cnpj && (
                  <BankInfoText>
                    {t('checkout_success_page.success.wire_transfer.cnpj', { cnpj: bankAccount.cnpj })}
                  </BankInfoText>
                )}
                <BankInfoText>
                  {t('checkout_success_page.success.wire_transfer.bank', { bank: bankAccount.bank })}
                </BankInfoText>
                {(bankAccount.agency && bankAccount.account) && (
                  <>
                    <BankInfoText>
                      {t('checkout_success_page.success.wire_transfer.ag', { ag: bankAccount.agency })}
                    </BankInfoText>
                    <BankInfoText>
                      {t('checkout_success_page.success.wire_transfer.cc', { cc: bankAccount.account })}
                    </BankInfoText>
                  </>
                )}
                {(bankAccount.pix && bankAccount.pix !== 'N/A') && (
                  <BankInfoText>
                    {t('checkout_success_page.success.wire_transfer.pix', { pix: bankAccount.pix })}
                  </BankInfoText>
                )}
              </>
            ) : (
              <>
                <Typography fontWeight={500}>
                  {t('checkout_success_page.success.wire_transfer.company')}
                </Typography>
                <BankInfoText>
                  {t('checkout_success_page.success.wire_transfer.default_cnpj')}
                </BankInfoText>
                <BankInfoText>
                  {t('checkout_success_page.success.wire_transfer.default_bank')}
                </BankInfoText>
                <BankInfoText>
                  {t('checkout_success_page.success.wire_transfer.default_ag')}
                </BankInfoText>
                <BankInfoText>
                  {t('checkout_success_page.success.wire_transfer.default_cc')}
                </BankInfoText>
                <BankInfoText>
                  {t('checkout_success_page.success.wire_transfer.default_pix')}
                </BankInfoText>
              </>
            )}
          </Box>
          <Box display="flex">
            <ItemNumberWrapper>
              <Typography>{'2'}</Typography>
            </ItemNumberWrapper>
            <Typography
              alignSelf="center"
              textAlign="left"
              sx={{
                span: {
                  a: {
                    color: 'info.main',
                    fontWeight: 500
                  },
                  'a:hover': {
                    textDecoration: 'none'
                  }
                }
              }}
            >
              <HtmlTranslation text={t('checkout_success_page.success.wire_transfer.step_2')} />
            </Typography>
          </Box>
        </Box>
      </Box>
    </ContentWrapper>
  );

  const SuccessMessage = () => (
    <ContentWrapper
      bgcolor='secondary.main'
      textAlign="center"
      justifyContent="center"
      mt={5}
      px={{ xs: 1, md: 6 }}
      flexDirection='column'
      overflowY='scroll'
    >
      <Box
        className={`rent-requested-${paymentMethod}`}
        px={2}
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
      >
        <SvgIcon
          className={`rent-requested-${paymentMethod}-success-img`}
          name="success_large"
          mb={1.5}
        />
        <Typography
          fontWeight="bolder"
          color="primary"
          gutterBottom
          variant="h4"
        >
          {t('checkout_success_page.success.title')}
        </Typography>
        {!paymentLink && (
          <>
            <Typography
              fontWeight={500}
              gutterBottom
              color="primary.70"
              mb={2}
              fontSize={14}
              variant="h4"
            >
              {formStatus === 'Approved' && (t('checkout_success_page.success.approved_message'))}
              {formStatus === 'Submitted' && (t('checkout_success_page.success.message'))}
              {formStatus === 'Pending' && (t('checkout_success_page.success.pending_message'))}
            </Typography>
            { showSentRegularContractEmail && (<>
              <Typography
                fontWeight={500}
                gutterBottom
                color="primary.70"
                mb={2}
                fontSize={14}
                variant="h4"
              >
                {t('checkout_success_page.success.check_email_message')}
              </Typography></>)
            }
            <Typography
              fontWeight={500}
              gutterBottom
              color="primary.70"
              fontSize={14}
              variant="h4"
            >
              {t('checkout_success_page.success.message_please')}
            </Typography>
          </>
        )}
        <Box
          bgcolor="secondary.main"
          flexDirection="column"
          display="flex"
          mt={1}
        >
          <Link mt={0.5} mb={2} href="/">
            <Typography
              color="primary.70"
              variant="lead"
            >
              {t('checkout_success_page.success.return_home')}
            </Typography>
          </Link>
        </Box>
      </Box>
      <Box mb={1} alignSelf="center">
        <Typography mb={0.5}>
          {t('checkout_success_page.app_store_message')}
        </Typography>
        <Box>
          <Link rel="nofollow" target="_blank" href={googleAppUrl}>
            <SvgIcon name="google_store_large" size="150" mx={0.25} mt={0.25} />
          </Link>
          <Link rel="nofollow" target="_blank" href={appleAppUrl}>
            <SvgIcon name="apple_store_large" size="150" mx={0.25} mt={0.25} />
          </Link>
        </Box>
      </Box>
      <Copyright textColor="primary.main" fontSize="0.75rem" display={{ xs: 'flex', md: 'none' }} />
    </ContentWrapper>
  );

  return (
    <ThemeProvider>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        minHeight="100vh"
        height="100%"
      >
        <Box
          justifyContent="space-between"
          bgcolor="primary.main"
          flexDirection="column"
          display="flex"
          width={{ xs: '100%', md: '50%' }}
        >
          <ContentWrapper
            padding={{ xs: 1, lg: 0 }}
            paddingTop={{ xs: 1, md: 3, lg: 5 }}
          >
            <Link
              sx={{ display: { xs: 'none', md: 'flex' } }}
              href="javascript:window.history.back();"
              width={1}
            >
              <SvgIcon sx={{ mx: 1 }} name="arrow_left_white" />
              <SvgIcon name="logo_white" size="100" />
            </Link>
            <Box marginTop={{ xs: 0, md: 2.5, lg: 3.5 }} mb={0.5}>
              {!paymentLink && (
                <Chip
                  label={
                    <ChipLabel>
                      {`${numberOfNights} ${t('checkout_success_page.nights')}`}
                    </ChipLabel>
                  }
                  sx={{ fontSize: '10px', px: 0.5 }}
                  color="secondary"
                  size="small"
                />
              )}
            </Box>
            <SuccessTypography variant="lead">{neighbourhood} • {cityName}</SuccessTypography>
            <SuccessTypography fontWeight={['400', '400', 'bold']} variant="h5">{address}</SuccessTypography>
            <Box mt={{ xs: 1, lg: 2 }} mb={0.5}>
              <Chip
                label={
                  <ChipLabel >
                    {paymentLink
                      ? paymentLink.description
                      : t(`checkout_success_page.payment_type.${paymentType}`)}
                  </ChipLabel>
                }
                sx={{ fontSize: '10px', px: 0.5 }}
                color="secondary"
                size="small"
              />
            </Box>
            <SuccessTypography variant="h1" component="span">{`R$ ${formatCurrency(total)}`}</SuccessTypography>
            <Box
              alt={t('checkout_page.alt_img') + address}
              sx={{
                objectFit: 'cover',
                width: '90vw',
                maxWidth: 400,
                maxHeight: [{ xs: 104, lg: 251 }],
                borderRadius: 1,
                mt: 1
              }}
              variant="outlined"
              src={propertyImg}
              component="img"
            />
          </ContentWrapper>
          <Box
            sx={{ display: { xs: 'none', md: 'flex' } }}
            flexDirection="column"
            alignItems="center"
          >
            <Copyright
              display="inline-flex"
              textColor="secondary.main"
              mb={1}
            />
          </Box>
        </Box>
        <Box width={{ xs: '100%', md: '50%' }}>
          {paymentMethod === 'wire_transfer' && (reservationStatus !== 'Approved' && (formStatus === 'Submitted' || formStatus === 'Approved')) ? (
            <WireTransferMessage />
          ) : (['personal', 'nationality', 'address', 'additionalInfo', 'guests', 'success'].includes(step) ? (
            {
              personal: (
                <Personal
                  reservationInfo={reservationForm}
                  reservationFormSubmitPath={reservationFormSubmitPath}
                  previousStepUrl={previousStepUrl}
                  nextStepUrl={nextStepUrl}
                  formStatus={formStatus}
                />
              ),
              nationality: (
                <Nationality
                  reservationInfo={reservationForm}
                  reservationFormSubmitPath={reservationFormSubmitPath}
                  previousStepUrl={previousStepUrl}
                  nextStepUrl={nextStepUrl}
                  formStatus={formStatus}
                  simplifiedForm={simplifiedForm}
                />
              ),
              address: (
                <Address
                  reservationInfo={reservationForm}
                  reservationFormSubmitPath={reservationFormSubmitPath}
                  previousStepUrl={previousStepUrl}
                  nextStepUrl={nextStepUrl}
                  formStatus={formStatus}
                />
              ),
              additionalInfo: (
                <AdditionalInfo
                  reservationInfo={reservationForm}
                  reservationFormSubmitPath={reservationFormSubmitPath}
                  previousStepUrl={previousStepUrl}
                  nextStepUrl={nextStepUrl}
                  maxGuests={maxGuests}
                  availableCheckInHours={availableCheckInHours}
                  formStatus={formStatus}
                  isPetFriendly={isPetFriendly}
                  hasParkingSlot={hasParkingSlot}
                  parkingSlotCount={parkingSlotCount}
                  reservationSource={reservationSource}
                />
              ),
              guests: (
                <AdditionalGuests
                  reservationInfo={reservationForm}
                  guestInfo={guestInfo}
                  reservationFormSubmitPath={reservationFormSubmitPath}
                  previousStepUrl={previousStepUrl}
                  nextStepUrl={nextStepUrl}
                  guestNextStepUrl={guestNextStepUrl}
                  guestPreviousStepUrl={guestPreviousStepUrl}
                  guestNumber={guestNumber}
                  currGuestsCount={currGuestsCount}
                  maxGuests={maxGuests}
                  maxCheckInTime={maxCheckInTime}
                  formStatus={formStatus}
                  simplifiedForm={simplifiedForm}
                />
              ),
              success: (
                <Success successComponent={<SuccessMessage />} formStatus={formStatus} />
              )
            }[step]
          ) : (
            <Box flex={1}>
              { ['adyen'].includes(paymentMethod) && !paymentSucceeded ? (
                {
                  adyen: (
                    <AdyenPaymentComponent
                      adyenClientKey={tabasAdyenClientKey}
                      adyenEnvironment={adyenEnvironment}
                      adyenActionPath={adyenActionPath}
                      adyenCardResponse={adyenCardResponse}
                      onPaymentSucceeded={() => setPaymentSucceeded(true)}
                      adyenSuccessCallbackPath={adyenCallbackSuccess}
                      paymentLink={paymentLink}
                      paymentType={paymentType}
                      adyenSessionInfo={adyenSessionInfo}
                      reservationFormPath={reservationFormPath}
                      adyenSaveLogPath={adyenSaveLogPath}
                    />
                  )
                }[paymentMethod]
              ) : (
                <SuccessMessage />
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default CheckoutPayment;
