import React, { useState } from 'react';
import { checkWebp } from '../utils/checkWebp';
import {
  Box,
  Button,
  Container,
  Typography,
  Link,
  SvgIcon,
  ButtonGroup,
  HtmlTranslation
} from '../UI/elements';
import {
  Swiper
} from '../UI/modules';
import {
  Hero,
  Section,
  SectionContent,
  Slider,
  slideImages,
  Scroller,
  ScrollWrapper,
  Title,
  PreTitle,
  leadersImages,
  seriesALogos,
  HeroWrapper,
  HeroContainer,
  HeroInfoWrapper,
  ImgWithInfo
} from './AboutUs.styles';
import { useBreakpoint } from '../hooks/useBreakpoint';
import { ThemeProvider } from '../contexts/theme';
import { t } from '../../js/common/translations';
import heroPng from '../../images/about_us/living-room.png';
import heroWebp from '../../images/about_us/living-room.webp';
import cardOneImg from '../../images/about_us/man-reading.png';
import confraTabas from '../../images/about_us/confra-tabas-2022.png';
import pagination from '../../images/icons/pagination.svg';
import work from '../../images/about_us/work.png';
import casatbHero from '../../images/casatb/about_us/casatb_hero.jpg';
import casatbAboutUs from '../../images/casatb/about_us/casatb_aboutus.png';
import landscape from '../../images/casatb/about_us/landscape.png';

const AboutUs = ({ casatb }) => {
  const [clientSwiper, setClientSwiper] = useState(null);
  const isWebp = checkWebp();
  const [clientSliderActive, setClientSliderActive] = useState(0);
  const mobile = ['xs', 'sm'].includes(useBreakpoint());

  const leadership = [
    {
      name: 'Leonardo Morgatto',
      photo: leadersImages.leonardoMorgatto,
      role: 'CEO',
      alt: t('about_us_alt.our_leadership.ceo')
    },
    {
      name: 'Simone Surdi',
      photo: leadersImages.simoneSurdi,
      role: 'COO',
      alt: t('about_us_alt.our_leadership.coo')
    },
    {
      name: 'Dino Meoni',
      photo: leadersImages.dinoMeoni,
      role: 'CTO',
      alt: t('about_us_alt.our_leadership.cto')
    }
  ];

  const casatbLeadership = [{
    name: 'Flávio Mendonça',
    photo: leadersImages.flavio,
    role: t('about_us.our_leadership.realestate'),
    alt: t('about_us.our_leadership.realestate'),
    casatb: casatb
  },
  {
    name: 'Murilo Gírio',
    photo: leadersImages.murilo,
    role: t('about_us.our_leadership.sales_html'),
    alt: t('about_us.our_leadership.sales_html'),
    casatb: casatb
  }];

  const seriesA = [
    {
      photo: seriesALogos.brazilJournalLogo,
      title: 'Tabas, a startup de sublocação, faz rodada para chegar a 1,2 mil apartamentos',
      url: 'https://braziljournal.com/tabas-a-startup-da-sublocacao-faz-rodada-para-chegar-a-12-mil-apartamentos'
    },
    {
      photo: seriesALogos.estadaoLogo,
      title: 'Startup imobiliária Tabas levanta US$ 14 milhões e aposta na ‘estadia flexível’',
      url: 'https://link.estadao.com.br/noticias/inovacao,startup-imobiliaria-tabas-levanta-us-14-milhoes-e-aposta-na-estadia-flexivel,70003954506'
    },
    {
      photo: seriesALogos.bloombergLogo,
      title: 'Tabas, proptech brasileña, levanta US$14 millones en capital y deuda',
      url: 'https://www.bloomberglinea.com/2022/01/19/tabas-competidor-de-houm-y-quinto-andar-levanta-us14-millones-en-capital-y-deuda/'
    },
    {
      photo: seriesALogos.valorEconomico,
      title: 'Tabas capta R$80 milhões para escalar \'aluguel flexível\'',
      url: 'https://pipelinevalor.globo.com/startups/noticia/tabas-capta-r-80-milhoes-para-escalar-aluguel-flexivel.ghtml'
    }
  ];

  const slides = [{
    type: 'city',
    image: slideImages.first
  }, {
    type: 'days',
    image: slideImages.second
  }, {
    type: 'renter',
    image: slideImages.third
  }];

  const ClientsSliderControls = () => (
    <Box
      sx={{
        position: 'absolute',
        p: 1.5,
        top: 0,
        right: 0,
        zIndex: 999,
        display: ['none', 'block']
      }}>
      <ButtonGroup
        buttons={[
          {
            children: <SvgIcon name="control_chevron_left" />,
            disabled: clientSliderActive === 0,
            onClick: () => clientSwiper?.slidePrev()
          },
          {
            children: <SvgIcon name="control_chevron_right" />,
            disabled: clientSliderActive === (slides.length - 1),
            onClick: () => clientSwiper?.slideNext()
          }
        ]}
        buttonProps={{
          variant: 'contained',
          color: 'secondary',
          size: 'large'
        }}
        variant="text"
      />
    </Box>
  );

  return (
    <ThemeProvider>
      <Box>
        <Container
          maxWidth="xl"
          disableGutters
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            px: [1, 1, 0],
            mb: 3
          }}
        >
          <HeroWrapper component="section" className="hero" sx={{ py: 1, width: 1 }}>
            <HeroContainer>
              <Hero component="picture">
                <Hero component="img" src={casatb ? casatbHero : (isWebp ? heroWebp : heroPng)} alt={`${t('about_us_alt.hero')}`} />
              </Hero>
              <HeroInfoWrapper sx={{ maxWidth: { xs: 400, lg: 800 } }}>
                <Box
                  color="white"
                  padding={1}
                  sx={{
                    backgroundColor: ['rgba(255, 255, 255, 0.5)', 'rgba(255, 255, 255, 0.5)', 'transparent'],
                    backdropFilter: [{ xs: 'blur(4px)', lg: 'none' }],
                    borderRadius: 4,
                    textAlign: { xs: 'center', md: 'left' }
                  }}
                >
                  <Typography mb={0.5} sx={{ display: !casatb && 'none', color: '#FECA2A' }}>{t('about_us.hero.pretitle')}</Typography>
                  <Typography
                    variant='h1'
                    sx={{
                      fontSize: ['1.5rem', '2.5rem'],
                      fontFamily: 'Cambon'
                    }}>
                    {t('about_us.hero.title')}
                  </Typography>
                  <Typography mt={1}>{t('about_us.hero.description')}</Typography>
                </Box>
              </HeroInfoWrapper>
            </HeroContainer>
          </HeroWrapper>
          <Section
            className="series-a"
            seriesA
            casatb={casatb}
            sx={{
              py: 3,
              px: 0.5,
              width: 1
            }}
          >
            <Box sx={{ padding: [1, 3], borderRadius: 2, maxWidth: ['960px', '1140px'] }}>
              <Typography variant='h1' component="h2" sx={{ fontFamily: 'Cambon' }}>
                {t('about_us.series_a.title.first') + ' '}
                <Box component='span' className="text-tabas-salmon">{t('about_us.series_a.title.second') + ' '}</Box>
                {t('about_us.series_a.title.third')}
              </Typography>
              <Typography
                sx={{
                  color: 'primary.70',
                  fontSize: ['1rem', '1.2rem'],
                  fontWeight: '400',
                  mt: 4
                }}
              >
                {t('about_us.series_a.sub-title.first')}
              </Typography>
            </Box>
            <Scroller
              sx={{
                py: 3,
                display: ['flex', 'grid', 'grid', 'flex'],
                justifyContent: ['flex-start', 'space-evenly', 'space-around'],
                flexWrap: ['nowrap', 'wrap', 'nowrap']
              }}
            >
              {seriesA.map((item, index) => (
                <ScrollWrapper
                  key={index}
                  seriesA
                  sx={{
                    mx: 0.75,
                    my: [0, 2, 2, 0]
                  }}
                >
                  <Box
                    component='img'
                    src={item.photo}
                    style={{
                      width: '150px'
                    }}
                  />
                  <Typography
                    component="span"
                    sx={{
                      mt: 1.5,
                      whiteSpace: 'break-spaces',
                      fontSize: '0.8rem',
                      fontWeight: '300'
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Link
                    className="text-tabas-salmon"
                    sx={{
                      textDecoration: 'none',
                      fontWeight: 'bold'
                    }}
                    href={item.url}
                    target="_blank"
                    rel="nofollow"
                  >
                    {t('about_us.series_a.see-more')}
                  </Link>
                </ScrollWrapper>
              ))}
            </Scroller>
          </Section>
          <Section className="future-goals">
            <Box component="picture"
              my={2}
              sx={{
                borderRadius: 3,
                width: [1, '700px', '650px'],
                height: ['300px', '350px', '450px']
              }}>
              <Box
                component="img"
                src={casatb ? casatbAboutUs : confraTabas} sx={{
                  borderRadius: 3,
                  objectFit: 'cover',
                  width: [1, '700px', '650px'],
                  height: ['300px', '350px', '450px']
                }}
                alt={t('about_us_alt.card_three')}
              />
            </Box>
            <Box
              sx={{
                textAlign: 'left',
                marginLeft: [1, 3, 1],
                width: 1,
                maxWidth: [1, '700px', '550px']
              }} >
              <PreTitle>
                {t('about_us.where.going_high')}
              </PreTitle>
              <Title>
                {t('about_us.where.title')}
              </Title>
              <SectionContent>
                <p>{t('about_us.where.first_paragraph')}</p>
                <p>{t('about_us.where.second_paragraph')}</p>
                <p>{t('about_us.where.third_paragraph')}</p>
              </SectionContent>
              <Link
                className="text-tabas-salmon"
                sx={{
                  textDecoration: 'none',
                  fontWeight: 'bold',
                  display: casatb && 'none'
                }}
                href={t('about_us.where.cta_url')}
                target="_blank"
                rel="nofollow"
              >
                {t('about_us.where.cta_text')}
              </Link>
            </Box>
          </Section>
          <Section inverted className="reason-why">
            <Box
              sx={{
                textAlign: 'left',
                marginLeft: [1, 3, 1],
                width: 1,
                py: 2,
                maxWidth: [1, '700px', '550px']
              }}
            >
              <PreTitle>
                {t('about_us.hero.find_out')}
              </PreTitle>
              <Title>
                {t('about_us.hero.motivation')}
              </Title>
              <SectionContent>
                <p>{t('about_us.hero.first_paragraph')}</p>
                <p>{t('about_us.hero.second_paragraph')}</p>
                <p>{t('about_us.hero.third_paragraph')}</p>
              </SectionContent>
            </Box>
            <Box component="picture"
              sx={{
                marginRight: 1,
                marginLeft: [1, 3, 0],
                borderRadius: 3,
                width: [1, '700px', '650px'],
                height: ['300px', '350px', '450px']
              }}>
              <Box
                component="img"
                src={cardOneImg}
                sx={{
                  marginRight: 1,
                  marginLeft: [1, 3, 0],
                  borderRadius: 3,
                  objectFit: 'cover',
                  width: [1, '700px', '650px'],
                  height: ['300px', '350px', '450px']
                }}
                alt={t('about_us_alt.card_one')}
              />
            </Box>
          </Section>
          <Section casatb={casatb} className="numbers">
            <Box
              sx={{
                textAlign: 'left',
                marginLeft: [1, 3, 1],
                width: 1,
                maxWidth: [1, '700px', '550px']
              }}
            >
              <PreTitle>
                {t('about_us.tabas_today.we_are')}
              </PreTitle>
              <Title>
                {t('about_us.tabas_today.title')}
              </Title>
              <SectionContent>
                {t('about_us.tabas_today.first_paragraph')}<br />
                <p>{t('about_us.tabas_today.our_journey')}</p>
                <Box component='ul' style={{ fontSize: ['0.75rem', '1rem'] }} >
                  <li>{t('about_us.tabas_today.how_it_started')}</li>
                  <li>{t('about_us.tabas_today.more_apartments')}</li>
                  <li>{t('about_us.tabas_today.more_rented')}</li>
                  <li>{t('about_us.tabas_today.living_tabas')}</li>
                </Box>
              </SectionContent>
            </Box>
            <Box
              sx={{
                marginRight: 1,
                my: 2,
                marginLeft: [1, 3, 0],
                borderRadius: 3,
                width: [1, '700px', '650px'],
                height: ['350px', '400px', '450px']
              }}
            >
              <Swiper
                onSwiper={setClientSwiper}
                initialSlide={0}
                style={{
                  paddingBottom: 1
                }}
                onSlideChange={({ activeIndex }) => setClientSliderActive(activeIndex)}
                slides={slides.map((slide, i) => (
                  <Box key={i} sx={{ height: ['90%', '90%', '100%'] }}>
                    <Slider bgImageSrc={slide.image}
                      sx={{
                        borderRadius: 3,
                        height: '100%',
                        pb: 1,
                        overflow: 'hidden',
                        m: 0
                      }}
                    >
                      <ClientsSliderControls />
                      <Box
                        sx={{
                          borderRadius: 3,
                          width: 1,
                          height: { xl: 'auto', sm: '75px' },
                          color: 'black',
                          backdropFilter: 'blur(4px)',
                          textAlign: 'start',
                          px: 1,
                          py: 1,
                          background: 'rgba(255,255,255,0.6)'
                        }}
                      >
                        <Typography>{t(`about_us.tabas_today.${slide.type}`)}</Typography>
                      </Box>
                    </Slider>
                  </Box>
                ))}
                styles={{
                  container: {
                    height: '100%'
                  },
                  pagination: {
                    el: '.swiper-pagination',
                    paddingTop: '20px'
                  }
                }}
                spaceBetween={30}
                loop={false}
                disablePagination={!mobile}
              />
            </Box>
          </Section>
          <Section casatb={!casatb}>
            <Box component="picture"
              my={2}
              sx={{
                borderRadius: 3,
                width: [1, '700px', '650px'],
                height: ['300px', '350px', '450px'],
                position: 'relative'
              }}>
              <Box
                casatb={casatb}
                component="img"
                src={casatb ? landscape : confraTabas}
                sx={{
                  borderRadius: 3,
                  objectFit: 'cover',
                  width: [1, '700px', '650px'],
                  height: ['300px', '350px', '450px']
                }}
                alt={t('about_us_alt.card_three')}
              />
              <ImgWithInfo>
                <Typography>{t('about_us.expansion.image_info')}</Typography>
              </ImgWithInfo>
            </Box>
            <Box
              sx={{
                textAlign: 'left',
                marginLeft: [1, 3, 1],
                width: 1,
                maxWidth: [1, '700px', '550px']
              }} >
              <PreTitle>
                {t('about_us.expansion.growth')}
              </PreTitle>
              <Title>
                {t('about_us.expansion.title')}
              </Title>
              <SectionContent>
                <p>{t('about_us.expansion.first_paragraph')}</p>
                <p>{t('about_us.expansion.second_paragraph')}</p>
                <p>{t('about_us.expansion.third_paragraph')}</p>
                <p>{t('about_us.expansion.fourth_paragraph')}</p>
              </SectionContent>
              <Link
                className="text-tabas-salmon"
                sx={{
                  textDecoration: 'none',
                  fontWeight: 'bold',
                  display: casatb && 'none'
                }}
                href={t('about_us.where.cta_url')}
                target="_blank"
                rel="nofollow"
              >
                {t('about_us.where.cta_text')}
              </Link>
            </Box>
          </Section>
          <Section className="leadership">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: 1
              }}
            >
              <Typography
                component="h2"
                variant='h1'
                sx={{
                  fontWeight: 'bold',
                  mt: 3,
                  fontFamily: 'Cambon'
                }}
              >
                {t('about_us.roles.leadership')}
              </Typography>
              <Box component='img'
                sx={{
                  width: 1,
                  height: '5px',
                  mt: 1,
                  display: ['flex', 'none']
                }}
                src={pagination}
              />
              <Scroller
                sx={{
                  py: 3,
                  display: ['flex', 'grid', 'grid', 'flex'],
                  justifyContent: ['flex-start', 'space-evenly', 'space-around'],
                  flexWrap: ['nowrap', 'wrap', 'nowrap']
                }}
              >
                {leadership.map((item, index) => (
                  <ScrollWrapper key={index} sx={{ mx: 2, my: [2, 2, 0] }}>
                    <Box component='picture' style={{
                      borderRadius: '100%',
                      height: '150px',
                      width: '150px',
                      boxShadow: '0 0 0 6px #fff, 0 0 0 10px #dbb95a'
                    }}>
                      <Box
                        component='img'
                        src={item.photo}
                        style={{
                          borderRadius: '100%',
                          height: '150px',
                          width: '150px',
                          boxShadow: '0 0 0 6px #fff, 0 0 0 10px #dbb95a'
                        }}
                        alt={item.alt}
                      />
                    </Box>
                    <Typography
                      component="span"
                      sx={{
                        mt: 1.5,
                        fontWeight: 'bold'
                      }}
                    >
                      <HtmlTranslation text={item.name} />
                    </Typography>
                    <Typography component="span">
                      {item.role}
                    </Typography>
                  </ScrollWrapper>
                ))}
                {casatb && casatbLeadership.map((item, index) => (
                  <ScrollWrapper key={index} sx={{ mx: 2, my: [2, 2, 0] }}>
                    <Box component='picture' style={{
                      borderRadius: '100%',
                      height: '150px',
                      width: '150px',
                      boxShadow: '0 0 0 6px #fff, 0 0 0 10px #dbb95a'
                    }}>
                      <Box
                        component='img'
                        src={item.photo}
                        style={{
                          borderRadius: '100%',
                          height: '150px',
                          width: '150px',
                          boxShadow: '0 0 0 6px #fff, 0 0 0 10px #dbb95a'
                        }}
                        alt={item.alt}
                      />
                    </Box>
                    <Typography
                      component="span"
                      sx={{
                        mt: 1.5,
                        fontWeight: 'bold'
                      }}
                    >
                      <HtmlTranslation text={item.name} />
                    </Typography>
                    <Typography component="span" sx={{ width: '200px' }}>
                      {item.role}
                    </Typography>
                  </ScrollWrapper>
                ))}
              </Scroller>
            </Box>
          </Section>
          <Section style={{ padding: 0, justifyContent: 'space-between', display: casatb && 'none' }} className="career">
            <Box
              sx={{
                display: 'flex',
                flexDirection: ['column-reverse', 'row'],
                width: 1
              }}
            >
              <Box
                sx={{
                  textAlign: 'left',
                  marginLeft: [0, 3],
                  p: [1],
                  pb: [1.5, 0],
                  maxWidth: [1, '700px', '550px'],
                  mt: 1
                }}
              >
                <Title>
                  {t('about_us.work.title')}
                </Title>
                <SectionContent>
                  {t('about_us.work.paragraph')}
                </SectionContent>
                <Button
                  variant="contained"
                  href={t('about_us.work.work_with_us_path')}
                  size="large"
                  sx={{
                    mt: 2
                  }}
                >
                  {t('about_us.work.button')}
                </Button>
              </Box>
              <Box component="picture"
                sx={{
                  clipPath: ['polygon(0 0, 100% 0, 100% 75%, 0% 100%)',
                    'polygon(0 0, 100% 0, 100% 100%, 30% 100%)'],
                  borderRadius: ['1rem 1rem 1rem 0rem', '0rem 1rem 1rem 1rem'],
                  width: 1,
                  height: '300px'
                }}>
                <Box component="img"
                  src={work}
                  sx={{
                    clipPath: ['polygon(0 0, 100% 0, 100% 75%, 0% 100%)',
                      'polygon(0 0, 100% 0, 100% 100%, 30% 100%)'],
                    objectFit: 'cover',
                    borderRadius: ['1rem 1rem 1rem 0rem', '0rem 1rem 1rem 1rem'],
                    width: 1,
                    height: '300px'
                  }} />
              </Box>
            </Box>
          </Section>
          <Section casatb={casatb} style={{ flexDirection: 'column' }}>
            <Title style={{ marginTop: '1rem' }}>
              {t('address_info.title')}
            </Title>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, gap: { xs: '1rem', lg: 0 }, margin: { sm: '1rem 3rem' } }}>
              <Box display="flex" flexDirection="row">
                <SvgIcon name="location" size="18" mr={1} />
                <SectionContent sx={{ textAlign: 'left', maxWidth: { xs: '100% !important', lg: '550px !important' } }}>
                  {t('address_info.sp')}
                </SectionContent>
              </Box>
              <Box display="flex" flexDirection="row">
                <SvgIcon name="location" size="18" mr={1} />
                <SectionContent sx={{ textAlign: 'left', maxWidth: { xs: '100% !important', lg: '550px !important' } }}>
                  {t('address_info.rj')}
                </SectionContent>
              </Box>
            </Box>
          </Section>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default AboutUs;
